<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--8">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-2">
                            <custom-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></custom-pagination>
                        </div>
                        <div class="col-6">
                            <!-- <base-input input-classes="form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search')"/> -->
                        </div>
                        <div class="col-2">
                            <a :href="apiUrl+'EuMaterial/export?'+this.paramExport+'&token='+token" class="btn btn-sm btn-dark float-right mr--6">Export as Excel</a>
                        </div>
                        <div class="col-2">
                            <el-dropdown trigger="click" class="float-left ml-6">
                                <span class="el-dropdown-link btn btn-dark btn-sm">
                                    View<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <div class="container">
                                        <base-checkbox v-model="checked.material_number" class="mb--1">
                                            {{ tt('material_number') }}
                                        </base-checkbox>
                                        <base-checkbox v-model="checked.item_name" class="mb--1">
                                            {{ tt('item_name') }}
                                        </base-checkbox>
                                        <base-checkbox v-model="checked.short_description" class="mb--1">
                                            {{ tt('short_description') }}
                                        </base-checkbox>
                                        <base-checkbox v-model="checked.uom" class="mb--1">
                                            {{ tt('uom') }}
                                        </base-checkbox>
                                        <base-checkbox v-model="checked.material_type" class="mb--1">
                                            {{ tt('material_type') }}
                                        </base-checkbox>
                                        <base-checkbox v-model="checked.material_group" class="mb--1">
                                            {{ tt('material_group') }}
                                        </base-checkbox>
                                        <base-checkbox v-model="checked.cat_type" class="mb--1">
                                            {{ tt('cat_type') }}
                                        </base-checkbox>
                                        <base-checkbox v-model="checked.cat_status" class="mb--1">
                                            {{ tt('cat_status') }}
                                        </base-checkbox>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
                <div class="mt--4">
                    <el-table height="200px" ref="multipleTable" :data="table.data" class="table-responsive table-flush" header-row-class-name="thead-light" v-if="!onLoad.table" highlight-current-row @current-change="handleCurrentChange">
                        <el-table-column :label="tt('mat_no')" prop="material_number" v-if="checked.material_number" min-width="125px" sortable></el-table-column>
                        <el-table-column :label="tt('item_name')" prop="item_name" v-if="checked.item_name" min-width="200px" sortable></el-table-column>
                        <el-table-column :label="tt('short_description')" prop="short_description" v-if="checked.short_description" min-width="300px" sortable></el-table-column>
                        <el-table-column :label="tt('uom')" prop="uom" min-width="110px" v-if="checked.uom" sortable></el-table-column>
                        <el-table-column :label="tt('m_type')" prop="material_type" v-if="checked.material_type" min-width="120px" sortable></el-table-column>
                        <el-table-column :label="tt('m_group')" prop="material_group" v-if="checked.material_group" min-width="130px" sortable></el-table-column>
                        <el-table-column :label="tt('cat_type')" prop="cat_type" min-width="130px" v-if="checked.cat_type" sortable></el-table-column>
                        <el-table-column :label="tt('cat_status')" prop="cat_status" min-width="150px" v-if="checked.cat_status" sortable></el-table-column>
                    </el-table>
                    <page-loading v-else/>
                </div>
                <div style="margin-top: 20px;">
                    <div class="col-md-12" v-if="!onLoad.detail">
                        <div class="mb-2">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist" style="font-size: 11px;overflow-x: auto;overflow-y: hidden;flex-wrap: nowrap; text-transform: uppercase;">
                                <a :class="tabs.basic_data ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('basic_data')" role="tab" aria-controls="nav-home" :aria-selected="tabs.basic_data"><b>{{ tt('basic_data_classification') }}</b></a>
                                <a :class="tabs.mpn ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('mpn')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mpn"><b>{{ tt('mpn') }}</b></a>
                                <a :class="tabs.po_text ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('po_text')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.po_text"><b>{{ tt('po_text') }}</b></a>
                                <a :class="tabs.plant ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('plant')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.plant"><b>{{ tt('plant_storage_location') }}</b></a>
                                <a :class="tabs.purchasing ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('purchasing')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.purchasing"><b>{{ tt('purchasing') }}</b></a>
                                <a :class="tabs.sales ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('sales')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.sales"><b>{{ tt('sales') }}</b></a>
                                <a :class="tabs.mrp ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('mrp')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mrp"><b>{{ tt('mrp') }}</b></a>
                                <a :class="tabs.accounting ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('accounting')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.accounting"><b>{{ tt('accounting') }}</b></a>
                                <a :class="tabs.costing ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('costing')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.costing"><b>{{ tt('costing') }}</b></a>
                                <a :class="tabs.equipment ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('equipment')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.equipment"><b>{{ tt('equipment') }}</b></a>
                                <a :class="tabs.attachment ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('attachment')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.attachment"><b>{{ tt('attachment') }}</b></a>
                                <a :class="tabs.keyword ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('keyword')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.keyword"><b>{{ tt('keyword_old_number') }}</b></a>
                                <a :class="tabs.mcr ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('mcr')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mcr"><b> {{ tt('mcr_number') }}</b></a>
                            </div>
                        </div>

                        <!-- BASIC DATA & CLASS -->

                        <div v-if="tabs.basic_data">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header pb-2">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h4>{{ tt('basic_data') }}</h4>
                                                </div>
                                                <div class="col-6 text-right"></div>
                                            </div>
                                        </div>
                                        <div class="card-body" style="height:500px;overflow-y: scroll;">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('item_name') }}</label>
                                                    <base-input :placeholder="tt('item_name')" v-model="dataMaterial.item_name" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                            </div>
                                            <div class="row mt--3">
                                                <div class="col-12">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('group_class') }}</label>
                                                    <base-input :placeholder="tt('group_class')" v-model="dataMaterial.group_class" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                            </div>
                                            <div class="row mt--3">
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('material_type') }}</label>
                                                    <base-input :placeholder="tt('material_type')" v-model="dataMaterial.material_type" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('item_type') }}</label>
                                                    <base-input :placeholder="tt('item_type')" v-model="dataMaterial.item_type" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                            </div>
                                            <div class="row mt--3">
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('material_group') }}</label>
                                                    <base-input :placeholder="tt('material_group')" v-model="dataMaterial.material_group" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('catalog_type') }}</label>
                                                    <base-input :placeholder="tt('catalog_type')" v-model="dataMaterial.catalog_type" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                            </div>
                                            <div class="row mt--3">
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('scope_of_supply') }}</label>
                                                    <base-input :placeholder="tt('material_group')" v-model="dataMaterial.scope_of_supply" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('fab_non_fab') }}</label>
                                                    <base-input :placeholder="tt('fab_non_fab')" v-model="dataMaterial.fab_non_fab" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                            </div>
                                            <div class="row mt--3">
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('unit_of_measure') }}</label>
                                                    <base-input :placeholder="tt('unit_of_measure')" v-model="dataMaterial.uom" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('bom_non_bom') }}</label>
                                                    <base-input :placeholder="tt('bom_non_bom')" v-model="dataMaterial.bom_non_bom" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                            </div>
                                            <div class="row mt--3">
                                                <div class="col-6">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('crit_non_crit') }}</label>
                                                    <base-input :placeholder="tt('crit_non_crit')" v-model="dataMaterial.critical_non_critical" input-classes="form-control-sm" readonly></base-input>
                                                </div>
                                            </div>
                                            <div class="row mt--3">
                                                <div class="col-12">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('po_text_memo') }}</label>
                                                    <textarea class="form-control" rows="3" v-model="dataMaterial.po_text_memo" input-classes="form-control-sm" readonly></textarea>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-12">
                                                    <label class="form-control-label" style="font-size: 12px">{{ tt('internal_note') }}</label>
                                                    <textarea class="form-control" rows="3" v-model="dataMaterial.note" readonly></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header pb-2">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h4>{{ tt('classification') }}</h4>
                                                </div>
                                                <div class="col-6 text-right"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.data_part_char_value">
                                                <el-table-column :label="tt('characteristics')" :prop="tt('characteristics')" min-width="200px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.characteristic}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('value')" :prop="tt('value')" min-width="200px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.characteristic_value}}
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- MPN -->

                        <div v-else-if="tabs.mpn">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('manufacturer') }}</h4>
                                        </div>
                                        <div class="col-6 text-right"></div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_manufacture_ref">
                                        <el-table-column :label="tt('mancode')" :prop="tt('mancode')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.manufacture_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('manufacture_refference')" :prop="tt('manufacture_refference')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.manufacture_ref}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('source_type')" :prop="tt('source_type')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.source_type_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('type')" :prop="tt('type')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.type}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('notes')" :prop="tt('notes')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.note}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                        <!-- PO TEXT -->

                        <div v-else-if="tabs.po_text">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header pb-2">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h4>{{ tt('purchase_order_text') }}</h4>
                                                </div>
                                                <div class="col-6 text-right"></div>
                                            </div>
                                        </div>
                                        <div class="card-body" style="height:500px;overflow-y: scroll;">
                                            <h5 v-if="dataMaterial.item_name">=== {{ dataMaterial.item_name }} ===</h5>
                                            <div class='row' v-for="row in detailMaterial.po_text">
                                                <span class='col-5' style='font-size: 12px'>{{row.characteristic}}</span>
                                                <span class='col-5' style='font-size: 12px'>: {{row.characteristic_value}}</span>
                                            </div>

                                            <div class="mt-4" v-if="dataMaterial.po_text_memo">
                                                <h5>{{ tt('po_text_memo') }}</h5>
                                                <div style='font-size: 12px'>{{dataMaterial.po_text_memo}}</div>
                                            </div>

                                            <h5 class="mt-4" v-if="detailMaterial.part_equipment_code && dataMaterial.item_type_code == 'OEM'">{{ tt('equipment') }}</h5>
                                            <div class='row mt-3' v-for="row in detailMaterial.part_equipment_code" v-if="dataMaterial.item_type_code == 'OEM'">
                                                <span class="col-12" style='font-size: 12px'>S/P FOR {{ row.equipment_name + ' ('+ row.equipment_code +')' }}</span>
                                                <span class='col-5' style='font-size: 12px'>EQUIPMENT NAME</span>
                                                <span class='col-5' style='font-size: 12px'>: {{ row.equipment_name }}</span>

                                                <span class='col-5' style='font-size: 12px'>TYPE</span>
                                                <span class='col-5' style='font-size: 12px'>: {{ row.type }}</span>

                                                <span class='col-5' style='font-size: 12px'>SERIAL NUMBER</span>
                                                <span class='col-5' style='font-size: 12px'>: {{ row.serial_number }}</span>

                                                <span class='col-5' style='font-size: 12px'>MANUFACTURER</span>
                                                <span class='col-5' style='font-size: 12px'>: {{ row.manufacturer }}</span>

                                                <span class='col-5' style='font-size: 12px'>ADDITIONAL DATA</span>
                                                <span class='col-5' style='font-size: 12px'>: {{ row.additional_data }}</span>
                                            </div>

                                            <h5 class="mt-4" v-if="detailMaterial.part_manufacture_ref">{{ tt('manufacturer_reference') }}</h5>
                                            <div class='row mt-3' v-for="(row, index) in detailMaterial.part_manufacture_ref">
                                                <span class='col-5' style='font-size: 12px'>{{row.manufacture_code}}</span>
                                                <span class='col-5' style='font-size: 12px'>: {{row.source_type_code +':'+ row.manufacture_ref}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header pb-2">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h4>{{ tt('up_long_description') }}</h4>
                                                </div>
                                                <div class="col-6 text-right"></div>
                                            </div>
                                        </div>
                                        <div class="card-body" style="height:500px;overflow-y: scroll;">
                                            <span v-for="(row, index) in detailMaterial.long_description" style='font-size: 12px'>
                                                {{row.long_description}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- PLANT & STORAGE LOCATION -->

                        <div v-else-if="tabs.plant">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header pb-2">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h4>{{ tt('plant') }}</h4>
                                                </div>
                                                <div class="col-6 text-right"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_plant">
                                                <el-table-column :label="tt('plant')" :prop="tt('plant')" min-width="200px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.plant_code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('del_flag')" :prop="tt('del_flag')" min-width="150px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.del_flag}}
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header pb-2">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h4>{{ tt('storage_location') }}</h4>
                                                </div>
                                                <div class="col-6 text-right"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_storage_location">
                                                <el-table-column :label="tt('plant')" :prop="tt('plant')" min-width="200px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.plant_code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('storage_location')" :prop="tt('storage_location')" min-width="150px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.location_code}}
                                                    </template>
                                                </el-table-column>
                                              <!--   <el-table-column :label="tt('warehouse_number')" :prop="tt('warehouse_number')" min-width="150px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.warehouse_no}}
                                                    </template>
                                                </el-table-column> -->
                                                <el-table-column :label="tt('bin_location')" :prop="tt('bin_location')" min-width="150px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.bin_code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('del_flag')" :prop="tt('del_flag')" min-width="150px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.del_flag}}
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- SALES -->

                        <div v-else-if="tabs.sales">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('up_sales') }}</h4>
                                        </div>
                                        <div class="col-6 text-right"></div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_sales">
                                        <el-table-column :label="tt('sales_org')" :prop="tt('sales_org')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.sales_org_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('distr_channel')" :prop="tt('distr_channel')" min-width="170px" sortable>
                                            <template v-slot="{row}">
                                                {{row.distr_channel_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('sales_unit')" :prop="tt('sales_unit')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.sales_unit}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('division')" :prop="tt('division')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.division_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('tax_class')" :prop="tt('tax_class')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.tax_class_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('gen_item_cat_grp')" :prop="tt('gen_item_cat_grp')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.gen_item_cat_grp_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('account_assign_grp')" :prop="tt('account_assign_grp')" min-width="250px" sortable>
                                            <template v-slot="{row}">
                                                {{row.acc_ass_grp_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('item_cat_grp')" :prop="tt('item_cat_grp')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.item_cat_grp_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('trans_group')" :prop="tt('trans_group')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.trans_group_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('loading_group')" :prop="tt('loading_group')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.loading_group_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('profit_center')" :prop="tt('profit_center')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.profit_center_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('del_flag')" :prop="tt('del_flag')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.del_flag}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                        <!-- MRP -->

                        <div v-else-if="tabs.mrp">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('mrp') }}</h4>
                                        </div>
                                        <div class="col-6 text-right"></div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_mrp">
                                        <el-table-column :label="tt('plant')" :prop="tt('plant')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.plant_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('abc_indicator')" :prop="tt('abc_indicator')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.abc_indicator_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('mrp_type')" :prop="tt('mrp_type')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.mrp_type_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('mrp_controller')" :prop="tt('mrp_controller')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.mrp_controller_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('reorder_point')" :prop="tt('reorder_point')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.reorder_point}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('lot_size')" :prop="tt('lot_size')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.lot_size_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('fixed_lot')" :prop="tt('fixed_lot')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.fixed_lot}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('ord_costs')" :prop="tt('ord_costs')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.ord_costs}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('max_stock')" :prop="tt('max_stock')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.max_stock}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('min')" :prop="tt('min')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.min}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('max')" :prop="tt('max')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.max}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('procurement_type')" :prop="tt('procurement_type')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.procurement_type_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('special_proc')" :prop="tt('special_proc')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.special_proc_type_code}}
                                            </template>
                                        </el-table-column><el-table-column :label="tt('del_flag')" :prop="tt('del_flag')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.del_flag}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                        <!-- ACCOUNTING -->

                        <div v-else-if="tabs.accounting">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('up_accounting') }}</h4>
                                        </div>
                                        <div class="col-6 text-right"></div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_accounting">
                                        <el-table-column :label="tt('plant')" :prop="tt('plant')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.plant_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('val_class')" :prop="tt('val_class')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.valuation_class_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('val_category')" :prop="tt('val_category')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.valuation_category_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('val_type')" :prop="tt('val_type')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.valuation_type_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('price_ctrl')" :prop="tt('price_ctrl')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.price_control_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('price_determ')" :prop="tt('price_determ')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.price_determination}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('std_price')" :prop="tt('std_price')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.standard_price}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('price_unit')" :prop="tt('price_unit')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.price_unit}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('price_ref')" :prop="tt('price_ref')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.price_reference}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('del_flag')" :prop="tt('del_flag')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.del_flag}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                        <!-- COSTING -->

                        <div v-else-if="tabs.costing">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('up_costing') }}</h4>
                                        </div>
                                        <div class="col-6 text-right">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_costing">
                                        <el-table-column :label="tt('plant')" :prop="tt('plant')" min-width="100px" sortable>
                                            <template v-slot="{row}">
                                                {{row.plant_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('overhead_group')" :prop="tt('overhead_group')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.overhead_group}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('costing_lot_size')" :prop="tt('costing_lot_size')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.costing_lot_size}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('del_flag')" :prop="tt('del_flag')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.del_flag}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                        <!-- PURCHASING -->

                        <div v-else-if="tabs.purchasing">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('up_purchasing') }}</h4>
                                        </div>
                                        <div class="col-6 text-right">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_purchasing">
                                        <el-table-column :label="tt('plant')" :prop="tt('plant')" min-width="100px" sortable>
                                            <template v-slot="{row}">
                                                {{row.plant_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('purchasing_group')" :prop="tt('purchasing_group')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.purchasing_group_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('planned_delivery')" :prop="tt('planned_delivery')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.planned_delivery}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('order_unit')" :prop="tt('order_unit')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.order_unit}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('del_flag')" :prop="tt('del_flag')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.del_flag}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                        <!-- EQUIPMENT -->

                        <div v-else-if="tabs.equipment">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('up_equipment') }}</h4>
                                        </div>
                                        <div class="col-6 text-right"></div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_equipment_code">
                                        <el-table-column :label="tt('plant')" :prop="tt('plant')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.plant_code}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('equipment_code')" :prop="tt('equipment_code')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.equipment_code + ' - ' + row.equipment_description}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('equipment_name')" :prop="tt('equipment_name')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.equipment_name}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('serial_number')" :prop="tt('serial_number')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.serial_number}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('quantity_install')" :prop="tt('quantity_install')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.qty_installed}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('attachment')" :prop="tt('attachment')" min-width="250px" sortable>
                                            <template v-slot="{row}">
                                                <a :href="storageUrl+'drawing_master/'+row.file_name" target="_blank">{{row.file_name}}</a>
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('del_flag')" :prop="tt('del_flag')" min-width="150px" sortable>
                                            <template v-slot="{row}">
                                                {{row.del_flag}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                        <!-- ATTACHMENT -->

                        <div v-else-if="tabs.attachment">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('up_attachment_document') }}</h4>
                                        </div>
                                        <div class="col-6 text-right"></div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_attachment">
                                        <el-table-column :label="tt('file_name')" :prop="tt('file_name')" min-width="300px" sortable style="flex-wrap: nowrap;">
                                            <template v-slot="{row}">
                                                <a :href="row.file_attachment_code" target="_blank">{{row.file_attachment_code}}</a>
                                            </template>
                                        </el-table-column>
                                        <el-table-column :label="tt('created_at')" :prop="tt('created_at')" sortable>
                                            <template v-slot="{row}">
                                                {{row.created_at}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                        <!-- KEYWORD & OLD NO -->
                        <div v-else-if="tabs.keyword">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header pb-2">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h4>{{ tt('up_keyword') }}</h4>
                                                </div>
                                                <div class="col-6 text-right"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_keyword">
                                                <el-table-column :label="tt('keyword')" :prop="tt('keyword')" min-width="200px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.keyword}}
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header pb-2">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h4>{{ tt('up_old_material_number') }}</h4>
                                                </div>
                                                <div class="col-6 text-right"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_old_material_number">
                                                <el-table-column :label="tt('old_material_number')" :prop="tt('old_material_number')" min-width="200px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.old_material_number}}
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                         <!-- MCR NUMBER ITEM -->
                        <div v-else-if="tabs.mcr">
                            <div class="card">
                                <div class="card-header pb-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>{{ tt('mcr_number') }}</h4>
                                        </div>
                                        <div class="col-6 text-right">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="detailMaterial.part_mcr_number">
                                        <el-table-column :label="tt('mcr_number')" :prop="tt('mcr_number')" min-width="200px" sortable>
                                            <template v-slot="{row}">
                                                {{row.mcr_number}}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <page-loading v-else/>
                </div>
                <div class="card-footer" v-if="!onLoad.table">                              
                    <span class="float-right">
                        {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	import {mapState} from 'vuex'
	import Api from '@/helpers/api';
	import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
	import config from '@/configs/config';
    import baseApiUrl from '@/configs/config';
	
    export default {      
        data() {
            return {
	            loadTimeout: null,
	            params: this.$route.params,
	            checked: {
	            	material_number: true,
                    item_name: true,
                    short_description: true,
                    uom: true,
                    material_type: true,
                    material_group: true,
                    cat_type: true,
                    cat_status: true
                },
	            tabs: {
		            basic_data: false,
		            mpn: false,
		            po_text: false,
		            plant: false,
		            sales: false,
		            mrp: false,
                    accounting: false,
		            costing: false,
		            purchasing: false,
		            equipment: false,
		            attachment: false,
		            keyword: false,
                    mcr:false
	            },
	            onLoad: {
		            table: true,
		            detail: true
	            },
	            table: {
		            search: '',
		            total: 0,
                    data: [],
		            page: {
                        current_page: 1
                    },
	            },
                dataMaterial: {
                    item_name: '',
                    group_class: '',
                    material_type: '',
                    item_type: '',
                    material_group: '',
                    catalog_type: '',
                    scope_of_supply: '',
                    fab_non_fab: '',
                    uom: '',
                    bom_non_bom: '',
                    critical_non_critical: '',
                    po_text_memo: '',
                    note: '',
                },
                part_mcr_number:{},
                paramExport: [],
                detailMaterial: {},
                storageUrl: config.storageUrl,
                apiUrl: baseApiUrl.apiUrl,
                token: localStorage.token,
                toArray: [],
                string: '',
                removeKoma: ''
            }
        },
	    computed: {
		    ...mapState({
			    authUserPermission :state => state.auth.userPermission
		    }),
	    },
        mounted() {
        	this.tab('basic_data')
        	this.get()
            this.getParam()
        },
	    methods: {
		    get() {
			    let context = this;
			    
                if (this.$route.query.type == 'search-material') {
                    Api(context, endUserSearchMaterial.endUserSearchMaterial(this.table.page.current_page, this.$route.query)).onSuccess(function(response) {
                        context.table.total   = response.data.data.workbench.total;
                        context.table.data    = response.data.data.workbench.data;
                        context.table.page    = response.data.data.workbench;
                        context.onLoad.table  = false;
                        context.onLoad.detail = false;
                    }).onError(function(error) {
                        context.table.data    = [];
                        context.table.total   = 0;
                        context.onLoad.table  = false;
                        context.onLoad.detail = false;
                    }).call()
                } else {
                    Api(context, endUserSearchMaterial.endUserSearchByCharacteristic(this.table.page.current_page, this.$route.query)).onSuccess(function(response) {
                        context.table.total   = response.data.data.workbench.total;
                        context.table.data    = response.data.data.workbench.data;
                        context.table.page    = response.data.data.workbench;
                        context.onLoad.table  = false;
                        context.onLoad.detail = false;
                    }).onError(function(error) {
                        context.table.data    = [];
                        context.table.total   = 0;
                        context.onLoad.table  = false;
                        context.onLoad.detail = false;
                    }).call()
                }
		    },
            getParam() {
                var a = this.$route.query;

                if (this.$route.query.type == 'search-material') {
                    var url = Object.keys(a).map(function(k) {
                        return encodeURIComponent(k) + '=' + encodeURIComponent(a[k])
                    }).join('&')

                    this.paramExport = url;
                }else{

                    for (var i = 0; i < this.$route.query.char_value.length; i++) {
                        var char_value = 'char_value[]='+this.$route.query.char_value[i]+'&'
                        this.toArray.push(char_value);
                    }
                    this.toArray.push('type=search-material-by-characteristic');
                    this.string = this.toArray.toString()
                    this.removeKoma = this.string.replace(/,/g, "");
                    this.paramExport = this.removeKoma;
                }
            },
		    handleCurrentChange(val) {
			    let context = this;
			    context.onLoad.detail = true;
			    Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: val.material_number})).onSuccess(function(response) {
			    	context.detailMaterial = response.data.data;
			    	var part_master = response.data.data.part_master[0];
                    var part_mcr_number = response.data.data;
			    	context.dataMaterial.item_name = part_master.item_name;
			    	context.dataMaterial.group_class = part_master.nsc+' - '+part_master.nsc_description;
			    	context.dataMaterial.material_type = part_master.material_type+' - '+part_master.material_type_description;
			    	context.dataMaterial.material_group = part_master.material_group+' - '+part_master.material_group_description;
			    	context.dataMaterial.catalog_type = part_master.cat_type+' - '+part_master.cat_type_description;
                    context.dataMaterial.item_type = part_master.item_type_code+' - '+part_master.item_type_description;
			    	context.dataMaterial.item_type_code = part_master.item_type_code;
				    context.dataMaterial.scope_of_supply = part_master.scope_of_supply_code+' - '+part_master.sos_description;
				    context.dataMaterial.uom = part_master.uom+' - '+part_master.uom_description;
				    context.dataMaterial.fab_non_fab = part_master.fab_non_fab;
				    context.dataMaterial.bom_non_bom = part_master.bom_non_bom;
				    context.dataMaterial.critical_non_critical = part_master.critical_non_critical;
				    context.dataMaterial.po_text_memo = part_master.po_text_memo;
				    context.dataMaterial.note = part_master.note;
                    context.part_mcr_number = part_mcr_number.part_mcr_number;
				    context.onLoad.detail = false;
                    //console.log('ok')
			    }).onError(function(error) {
				    context.detailMaterial = [];
				    context.dataMaterial.item_name = '';
				    context.dataMaterial.group_class = '';
				    context.dataMaterial.material_type = '';
				    context.dataMaterial.material_group = '';
				    context.dataMaterial.catalog_type = '';
				    context.dataMaterial.item_type = '';
				    context.dataMaterial.scope_of_supply = '';
				    context.dataMaterial.uom = '';
				    context.dataMaterial.fab_non_fab = '';
				    context.dataMaterial.bom_non_bom = '';
				    context.dataMaterial.critical_non_critical = '';
				    context.dataMaterial.po_text_memo = '';
				    context.dataMaterial.note = '';
                    context.dataMaterial.mcr_number = '';
				    context.onLoad.detail = false;
			    }).call()
		    },
		    tab(type) {
			    if (type == 'basic_data') {
				    this.tabs.basic_data = true;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'mpn') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = true;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'po_text') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = true;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'plant') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = true;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'sales') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = true;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'mrp') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = true;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'accounting') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
                    this.tabs.accounting = true;
				    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    }else if (type == 'costing') {
                    this.tabs.basic_data = false;
                    this.tabs.mpn = false;
                    this.tabs.po_text = false;
                    this.tabs.plant = false;
                    this.tabs.sales = false;
                    this.tabs.mrp = false;
                    this.tabs.accounting = false;
                    this.tabs.costing = true;
                    this.tabs.purchasing = false;
                    this.tabs.equipment = false;
                    this.tabs.attachment = false;
                    this.tabs.keyword = false;
                    this.tabs.mcr = false;
                } else if (type == 'purchasing') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = true;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'equipment') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = true;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'attachment') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = true;
				    this.tabs.keyword = false;
                    this.tabs.mcr = false;
			    } else if (type == 'keyword') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = true;
                    this.tabs.mcr = false;
			    }else if (type == 'mcr') {
				    this.tabs.basic_data = false;
				    this.tabs.mpn = false;
				    this.tabs.po_text = false;
				    this.tabs.plant = false;
				    this.tabs.sales = false;
				    this.tabs.mrp = false;
				    this.tabs.accounting = false;
                    this.tabs.costing = false;
				    this.tabs.purchasing = false;
				    this.tabs.equipment = false;
				    this.tabs.attachment = false;
				    this.tabs.keyword = false;
                    this.tabs.mcr = true;
			    }
		    },
            changePage(page) {
                let context = this;
                context.onLoad.table  = true;
                context.onLoad.detail  = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            }
        }   
    };
</script>
<style></style>
